body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

svg {
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
}
.container {
  display: inline-block;
  position: relative;
  width: 100%;
  padding-bottom: 100%;
  vertical-align: middle;
  overflow: hidden;
}

.ion-toolbar {
  /* padding-top: constant(safe-area-inset-top) !important;
  padding-top: env(safe-area-inset-top) !important; */
  padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left);
  padding: constant(safe-area-inset-top) constant(safe-area-inset-right) constant(safe-area-inset-bottom) constant(safe-area-inset-left);
}

.ion-footer{
  padding-bottom : env(safe-area-inset-bottom) !important;
}
.tribute-container ul li {
  background-color: #f5f5f5; /* Background color */
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  padding: 10px;
  border-bottom: 1px solid #ddd;
  transition: background-color 0.3s ease; /* Hover animation */
}

.tribute-container ul li:hover {
  background-color: #e0e0e0; /* Hover background color */
}

@tailwind base;
@tailwind components;
@tailwind utilities;


