#parallelogram {
  margin: 0px 0px 27px 10px;
  -webkit-transform: skewX(-40deg);
  -moz-transform: skew(-40deg);
  -o-transform: skew(-40deg);
  /* background: red; */
  overflow: hidden;
  position: relative;
  box-shadow:50px 1px 1px -1px white
}

  #parallelogram2 {
    margin: 0 0 0 27px;
    -webkit-transform: skew(-40deg);
    -moz-transform: skew(-40deg);
    -o-transform: skew(-40deg);
    /* background: url(http://placekitten.com/301/301); */
    overflow: hidden;
    position: relative;
    width: 150px;
    /* border:1px solid */
  }

  #parallelogram3 {
    margin: 0 0 0 15px;
    -webkit-transform: skew(-40deg);
    -moz-transform: skew(-40deg);
    -o-transform: skew(-40deg);
    /* background: url(http://placekitten.com/301/301); */
    overflow: hidden;
    position: relative;
    width: 150px;
  }
  
  .image {
    /* background: url(http://placekitten.com/301/301); */
    position: absolute;
    top: -30px;
    left: -30px;
    right: -30px;
    bottom: -30px;
    -webkit-transform: skew(-20deg);
    -moz-transform: skew(-20deg);
    -o-transform: skew(-20deg);
  }